<template>
  <div>
    <BaseCard title="환경설정" modalid="my-modal">
      <template v-slot:comcode>
        <div class="d-flex">
          <b-form-group
            label="단지 ID"
            label-class="fw-medium"
            id="text"
            class="mr-4"
          >
            <b-form-input
              disabled
              type="text"
              :value="$store.state.AptId"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="단지명"
            label-class="fw-medium"
            id="text"
            class="mr-4"
          >
            <b-form-input
              disabled
              type="text"
              :value="$store.state.AptName"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="사용자 ID"
            label-class="fw-medium"
            id="text"
            class="mr-4"
          >
            <b-form-input
              disabled
              type="text"
              :value="$store.state.UserId"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="사용자명"
            label-class="fw-medium"
            id="text"
            class="mr-4"
          >
            <b-form-input
              disabled
              type="text"
              :value="$store.state.UserName"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="비밀번호"
            label-class="fw-medium"
            id="text"
            class="mr-4"
          >
            <b-button
              variant="outline-primary"
              @click="openChangePwModal"
              pill
              class="d-flex align-items-center px-3"
            >
              비밀번호 변경
            </b-button> 
          </b-form-group>
        </div>
      </template>
    </BaseCard>
    <b-modal id="modal-basic" hide-footer>
      <template #modal-title> 비밀번호 변경 </template>
      <div class="d-block">
        <b-col cols="12" lg="12">
          <b-form-group
            id="input1"
            label-cols-sm="12"
            label-cols-lg="12"
            content-cols-sm
            content-cols-lg="12"
            label="기존 비밀번호"
            label-for="input1"
            label-class="fw-medium"
          >
            <b-form-input id="input1" size="lg" type="password" v-model="password"></b-form-input>
          </b-form-group>

          <b-form-group
            id="input1"
            label-cols-sm="12"
            label-cols-lg="12"
            content-cols-sm
            content-cols-lg="12"
            label="새 비밀번호"
            label-for="input1"
            label-class="fw-medium"
          >
            <b-form-input id="input1" size="lg" type="password" v-model="newPassword"></b-form-input>
          </b-form-group>
          <p>새 비밀번호는 8자 이상 영문, 숫자, 특수문자를 포함하여 설정해주세요.</p>

          <b-form-group
            id="input1"
            label-cols-sm="12"
            label-cols-lg="12"
            content-cols-sm
            content-cols-lg="12"
            label="새 비밀번호 확인"
            label-for="input1"
            label-class="fw-medium"
          >
            <b-form-input id="input1" size="lg" type="password" v-model="newPassword2"></b-form-input>
          </b-form-group>
          <font color="red" v-if="passwordError != ''">{{ passwordError }}<br/><br/></font>
          <b-button
          @click="pwChangeDo"
          variant="outline-primary"
          pill
          class="d-flex align-items-center px-3"
        >제출</b-button> 
        <br/>
        </b-col>
      </div>
    </b-modal>
  </div>  
</template>

<script>
import { pswdChange } from '../api';
import BaseCard from '../components/card/BaseCard'
export default {
  name: "Settings",
  components:{
    BaseCard
  },
  methods: {
    openChangePwModal(){
      this.$bvModal.show('modal-basic');
    },
    async pwChangeDo(){
      this.passwordError = "";
      if(this.password == ""){
        this.passwordError = "기존 비밀번호를 입력하세요.";
        return;
      }
      if(this.newPassword == ""){
        this.passwordError = "새 비밀번호를 입력하세요.";
        return;
      }
      if(this.newPassword2 == ""){
        this.passwordError = "새 비밀번호 확인을 입력하세요.";
        return;
      }
      if(this.newPassword != this.newPassword2){
        this.passwordError = "새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.";
        return;
      }

      const pswdChangeRes = await pswdChange(this.password, this.newPassword);
      if(pswdChangeRes.error){
        this.passwordError = pswdChangeRes.message;
        if(pswdChangeRes.loginRequired){
          this.$router.replace("/login");
        }
        return;
      }

      this.$bvModal.hide('modal-basic');
      this.password = "";
      this.newPassword = "";
      this.newPassword2 = "";
      this.passwordError = "";

      this.$bvToast.toast("비밀번호를 변경했습니다.", {
          title: `성공`,
          variant: "success",
          solid: true
      })

    }
  },
  data: () => ({
    password: "",
    newPassword: "",
    newPassword2: "",
    passwordError: ""
  })
};
</script>

<style>
.form-control[readonly] {
  background-color: #ffffff;
}
</style>